import React from "react"
import BasePortableText from "@sanity/block-content-to-react"
import serializers from "./serializers"

const clientConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "d3xss7d2",
  dataset: process.env.GATSBY_SANITY_DATASET || "production",
}

const PortableText = ({ blocks }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers}
    {...clientConfig}
  />
)

export default PortableText
