import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

const clientConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "d3xss7d2",
  dataset: process.env.GATSBY_SANITY_DATASET || "production",
}

export default ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    // { maxWidth: 675 },
    clientConfig
  )
  return (
    <figure>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </figure>
  )
}
