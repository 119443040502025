import React from "react"
import { Link } from "gatsby"
import Figure from "./Figure"

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: Figure,
  },
}

export default serializers
